import React, { useEffect } from 'react';
import { bootstrapCameraKit, createMediaStreamSource, Transform2D } from '@snap/camera-kit';

const Camera = () => {
  useEffect(() => {
    let mediaRecorder;
    let downloadUrl;

    const liveRenderTarget = document.getElementById('canvas');
    const videoContainer = document.getElementById('video-container');
    const videoTarget = document.getElementById('video');
    const startRecordingButton = document.getElementById('start');
    const stopRecordingButton = document.getElementById('stop');
    const downloadButton = document.getElementById('download');

        // Elements for lens and source selection dropdowns
    const lensSelect = document.getElementById('lens-select');
    const sourceSelect = document.getElementById('source-select');

    async function init() {
      const cameraKit = await bootstrapCameraKit({
        apiToken: 'eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzM3MzY0MzAzLCJzdWIiOiJiZmZhNjNmMy0zYWY1LTQ1MjMtYmJmMS05MmFjMTI4MzE5YjJ-U1RBR0lOR345ZGIyNTNlNi0xZDA3LTQxZmUtOWQzOS1hMjIwNzhjOTZkYzAifQ.c9TcLkjNx72bTa-U0E0-zJSiYuI8RUG-sZZu-1tu0Q4',
      });

      const session = await cameraKit.createSession({ liveRenderTarget });

      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });

      const source = createMediaStreamSource(mediaStream);

      await session.setSource(source);
      await session.play();

      const { lenses } = await cameraKit.lensRepository.loadLensGroups([
        'a818d699-ab75-46ed-9605-77d4c5bd9a74',
      ]);

// Set up the Lens <select> dropdown
      createLensSelect(lensSelect, lenses, async (lens) => {
      await session.applyLens(lens);
      });


            // Set up the Camera Source <select> dropdown
      createSourceSelect(sourceSelect, async (source) => {
              try {
                await session.setSource(source);
                source.setTransform(Transform2D.MirrorX);

                // Adjust render size for mobile devices
                const shouldUsePortrait = document.body.offsetWidth <= 768; // Adjust breakpoint as needed
                if (shouldUsePortrait) {
                  source.setRenderSize(720, 1280);
                }
              } catch (error) {
                console.error(error);
                throw error;
              }
            });

      bindRecorder();
    }

    function bindRecorder() {
      startRecordingButton.addEventListener('click', () => {
        startRecordingButton.disabled = true;
        stopRecordingButton.disabled = false;
        downloadButton.disabled = true;
        videoContainer.style.display = 'none';

        const mediaStream = liveRenderTarget.captureStream(30);

        mediaRecorder = new MediaRecorder(mediaStream);
        mediaRecorder.addEventListener('dataavailable', (event) => {
          if (!event.data.size) {
            console.warn('No recorded data available');
            return;
          }

          const blob = new Blob([event.data]);

          downloadUrl = window.URL.createObjectURL(blob);
          downloadButton.disabled = false;

          videoTarget.src = downloadUrl;
          videoContainer.style.display = 'block';
        });

        mediaRecorder.start();
      });

      stopRecordingButton.addEventListener('click', () => {
        startRecordingButton.disabled = false;
        stopRecordingButton.disabled = true;

        mediaRecorder?.stop();
      });

      downloadButton.addEventListener('click', () => {
        const link = document.createElement('a');

        link.setAttribute('style', 'display: none');
        link.href = downloadUrl;
        link.download = 'camera-kit-web-recording.mp4';
        link.click();
        link.remove();
      });
    }

    init();

    // Cleanup listeners on unmount
    return () => {
      startRecordingButton?.removeEventListener('click', () => {});
      stopRecordingButton?.removeEventListener('click', () => {});
      downloadButton?.removeEventListener('click', () => {});
    };
  }, []);
// Lens and Source dropdown helper functions
const createLensSelect = (element, lenses, onChange) => {
  lenses.forEach((lens, index) => {
    const option = document.createElement('option');
    option.value = index;
    option.textContent = `Lens ${index + 1}`;
    element.appendChild(option);
  });

  element.addEventListener('change', (e) => {
    const selectedLens = lenses[e.target.value];
    onChange(selectedLens);
  });
};

const createSourceSelect = (element, onChange) => {
  navigator.mediaDevices.enumerateDevices().then((devices) => {
    devices.forEach((device) => {
      if (device.kind === 'videoinput') {
        const option = document.createElement('option');
        option.value = device.deviceId;
        option.textContent = device.label || `Camera ${element.children.length + 1}`;
        element.appendChild(option);
      }
    });
  });

  element.addEventListener('change', (e) => {
    const deviceId = e.target.value;
    navigator.mediaDevices
      .getUserMedia({ video: { deviceId: { exact: deviceId } } })
      .then((stream) => {
        const source = createMediaStreamSource(stream);
        onChange(source);
      });
  });
};

return (
  <div>
    <section>
      <canvas id="canvas"></canvas>
    </section>
    <section>
      <button id="start">Start Recording</button>
      <button id="stop" disabled>
        Stop Recording
      </button>
    </section>
    <section id="video-container" style={{ display: 'none' }}>
      <video loop controls autoPlay id="video"></video>
      <div>
        <button id="download">Download Video</button>
      </div>
    </section>
    <section>
      <label htmlFor="lens-select">Select Lens: </label>
      <select id="lens-select"></select>
    </section>
    <section>
      <label htmlFor="source-select">Select Camera Source: </label>
      <select id="source-select"></select>
    </section>
  </div>
);
};

export default Camera;
